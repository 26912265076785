import styled from 'styled-components';

export const LatestNewsWrapper = styled.div`
  --gap: 2rem;
  --line-offset: calc(var(--gap) / 2);
  --line-thickness: 1px;
  --line-color: #88888826;
  --line-length: 100%;

  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  overflow: hidden;
  gap: var(--gap);
  align-items: flex-start;
  padding-block: 1rem;

  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1.5rem;
  }
`;
