/* eslint-disable @typescript-eslint/no-explicit-any */
import CarCard from '@apps/components/CarCard';

import { CarCarouselProps } from '.';

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const AmpCarCarousel: React.FC<CarCarouselProps> = (props) => {
  const { cars, webView } = props;
  // ======================= HOOKS

  // ======================= STATE

  // ======================= API

  // ======================= EVENTS

  // ======================= VARIABLES

  // ======================= RENDER FUNCTIONS

  // ======================= VIEWS
  return (
    <amp-inline-gallery layout="container">
      <amp-base-carousel
        // layout="responsive"
        layout="fixed-height"
        // width="1"
        width="auto"
        height="200px"
        controls="never"
        // mixed-length
        visible-count="(min-width: 768px) 6, 1.5"
        advance-count="1"
        // autoplay
        // delay="3000"
        // data-next-button-aria-label="Go to next slide"
        // data-previous-button-aria-label="Go to previous slide"
        role="region"
        aria-label="Carousel of cars"
      >
        {cars.map((car: any) => (
          <div
            key={car.car_model_slug}
            style={{
              width: '20%',
            }}
          >
            <CarCard
              slug={car?.car_model_slug}
              name={car?.new_car_title}
              image={car?.car_model_image}
              monthlyPayment={car?.new_car_monthly_instalment_9}
              priceRange={[car?.lowest_price, car?.highest_price]}
              brand={car?.car_brand_slug}
              webView={webView}
            />
          </div>
        ))}
      </amp-base-carousel>
      <amp-inline-gallery-pagination
        layout="nodisplay"
        inset
        style={{
          bottom: '-20px',
        }}
      ></amp-inline-gallery-pagination>
    </amp-inline-gallery>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default AmpCarCarousel;
