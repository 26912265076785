import styled from 'styled-components';

export const NewsCategoryWrapper = styled.div`
  display: flex;
  gap: 1rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ImageWrapper = styled.div<{
  $aspectRatio: number;
  $width?: string;
  $maxWidth?: string;
}>`
  aspect-ratio: ${(props) => props.$aspectRatio};
  position: relative;
  width: ${(props) => props.$width};
  max-width: ${(props) => props.$maxWidth};
`;

export const BadgeHolder = styled.div`
  position: absolute;
  z-index: 20;
  margin-bottom: 0;
  top: auto;
  right: 0;
  left: 0;
`;

export const ContentWrapper = styled.div`
  padding: 0;

  @media only screen and (max-width: 768px) {
    padding-bottom: 1rem;
  }
`;

export const InnerContentWrapper = styled.div``;
