/* eslint-disable @typescript-eslint/no-explicit-any */
import { Key } from 'react';
import ImageContent from '@apps/components/ImageContent';
import ShowMoreButton from '@apps/components/ShowMoreButton';
import { getDateSlug } from '@apps/utils/get-date-slug';
import { TitleDivider } from '@stack/ui/components';
import dynamic from 'next/dynamic';
import useTranslation from 'next-translate/useTranslation';
const ImageInlineContent = dynamic(
  () =>
    import('@apps/components/ImageInlineContent').then(
      (mod) => mod.ImageInlineContent
    ),
  {
    ssr: false,
  }
);

import { localeConversions } from '@apps/utils/locale-conversion';
import { toLower } from 'lodash';

import {
  ContentWrapper,
  FooterActionWrapper,
  InnerContentWrapper,
  SectionWrapper,
} from './styles';

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const NewsSection: React.FC<any> = (props) => {
  // ======================= HOOKS
  const { news, webView } = props;
  const { lang } = useTranslation('common');
  const newLang = localeConversions(lang);

  // ======================= STATE
  const TopRead = news?.data?.[0];
  const remainRead = news?.data.slice(1, 7);

  // ======================= API

  // ======================= EVENTS

  // ======================= VARIABLES

  // ======================= RENDER FUNCTIONS
  const getPostHref = (post: any) => {
    if (!post?.post_title) return;
    const dateSlug = getDateSlug(post.post_created);
    return webView
      ? `/webview/${dateSlug}/${toLower(post?.post_slug)}`
      : `/${dateSlug}/${toLower(post?.post_slug)}`;
  };

  // ======================= VIEWS
  return (
    <TitleDivider title={news?.post_category_name[newLang]}>
      <SectionWrapper>
        <ImageInlineContent
          imageProps={{
            src: TopRead?.post_image,
            height: 315,
            width: 600,
            alt: TopRead?.post_title || '',
          }}
          imagePosition="top"
          titleClass="text-5xl"
          titleLine={1}
          title={TopRead?.post_title}
          titleSlug={TopRead?.post_slug}
          description={TopRead?.post_short_description}
          footerText={TopRead?.post_created}
          author={TopRead?.post_author}
          authorAvatar={TopRead?.post_author_profile_image}
          authorEmail={TopRead?.post_author_username}
          footerTextJustify="flex-start"
          href={getPostHref(TopRead)}
          webView={webView}
        />
        <ContentWrapper>
          {remainRead?.map((rd: any, index: Key) => {
            return (
              <InnerContentWrapper
                className="grid-item grid-border"
                key={index}
              >
                <ImageContent
                  title={rd?.post_title}
                  titleSlug={rd?.post_slug}
                  titleLine={2}
                  titleClass={'custom-text-base'}
                  badgeLabel={rd?.category_name}
                  footerText={rd?.post_created}
                  author={rd?.post_author}
                  authorAvatar={rd?.post_author_profile_image}
                  authorEmail={rd?.post_author_username}
                  href={getPostHref(rd)}
                  webView={webView}
                  imageProps={{
                    src: rd?.post_image,
                    height: 315,
                    width: 600,
                    alt: rd?.post_title || '',
                  }}
                  maxImageWidth={'40%'}
                  imagePosition={'left'}
                />
              </InnerContentWrapper>
            );
          })}
        </ContentWrapper>
      </SectionWrapper>
      <FooterActionWrapper>
        <ShowMoreButton
          href={
            webView
              ? `/webview/${news?.post_category_slug}`
              : `/${news?.post_category_slug}`
          }
        />
      </FooterActionWrapper>
    </TitleDivider>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default NewsSection;
