import styled from 'styled-components';

export const ContentWrapper = styled.div`
  margin-top: 1rem;
  --gap: 2rem;
  --line-offset: calc(var(--gap) / 2);
  --line-thickness: 1px;
  --line-color: #88888826;
  --line-length: 100%;

  display: grid;
  overflow: hidden;
  gap: var(--gap);

  grid-template-columns: repeat(3, minmax(0, 1fr));

  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1.5rem;
  }

  @media only screen and (max-width: 431px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1.5rem;
  }
`;

export const InnerContentWrapper = styled.div`
  @media only screen and (max-width: 768px) {
    padding-bottom: 1rem;
  }
`;

export const SectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 2rem;

  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export const BlockInner = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
  row-gap: 1rem;
  position: relative;
  padding-bottom: 1rem;

  &:before {
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    height: 1px;
    content: '';
    pointer-events: none;
    border-bottom: 1px solid #edeced;
  }
`;
