import styled from 'styled-components';

export const ImageWrap = styled.div`
  width: 150px;
  max-height: 180px;
  border-radius: 50px;
  clip-path: circle(40%);
  background-color: rgb(247, 247, 247);

  @media only screen and (max-width: 768px) {
    width: 130px;
    max-height: 130px;
  }
`;

export const ImageHoverWrapper = styled.div`
  transform: scale(1);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

export default ImageWrap;
