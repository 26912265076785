import styled from 'styled-components';

export const Box = styled.div`
  margin-top: 1rem;
  padding-block: 1rem;
  border-top: 1px solid #edeced;

  @media only screen and (max-width: 768px) {
    margin-top: 0;
    padding-bottom: 0;
    border-top: 0px;
  }
`;
