import styled from 'styled-components';

export const ContentWrapper = styled.div`
  padding-block: 0;
  margin-top: 1rem;

  @media only screen and (max-width: 768px) {
    margin-top: 0;
  }
`;

export const MostReadContentWrapper = styled.div`
  padding-block: 0;
  margin-top: 1rem;

  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin-top: 0;
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-top: 0;
  }

  @media only screen and (max-width: 431px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin-top: 0;
  }
`;

export const InnerContentWrapper = styled.div`
  @media only screen and (max-width: 768px) {
  }
`;

export const FooterActionWrapper = styled.div`
  margin-top: 1.875rem;

  @media only screen and (max-width: 768px) {
    margin: 0;
  }
`;

export const SectionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  margin-top: 1rem;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const BlockInner = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
  row-gap: 1rem;
  position: relative;
  padding-bottom: 1rem;

  &:before {
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    height: 1px;
    content: '';
    pointer-events: none;
    border-bottom: 1px solid #edeced;
  }
`;

export const StickyHolder = styled.div`
  width: 25%;
  margin-bottom: 0.875rem;

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const NewsHolder = styled.div`
  width: 75%;

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const InformativeHolder = styled.div`
  gap: 2rem;
  margin-bottom: 1rem;

  @media only screen and (max-width: 1024px) {
    margin-bottom: 0;
  }
`;

export const MostReadWrapper = styled.div`
  @media only screen and (max-width: 768px) {
    margin-top: 1rem;
  }
`;
