/* eslint-disable @typescript-eslint/no-explicit-any */
import ModelItem from '../ModelGroup/ModelItem';

import { ModelCarouselProps } from '.';

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const AmpModelCarousel: React.FC<ModelCarouselProps> = (props) => {
  const { models } = props;

  // ======================= VIEWS
  return (
    <amp-inline-gallery layout="container">
      <amp-base-carousel
        // layout="responsive"
        layout="fixed-height"
        // width="1"
        width="auto"
        height="200px"
        controls="never"
        // mixed-length
        visible-count="(min-width: 768px) 6, 1.5"
        advance-count="1"
        // autoplay
        // delay="3000"
        // data-next-button-aria-label="Go to next slide"
        // data-previous-button-aria-label="Go to previous slide"
        role="region"
        aria-label="Carousel of cars"
      >
        {models.map((model: any) => (
          <div
            key={model.car_model_slug}
            style={{
              width: '20%',
            }}
          >
            <ModelItem
              href={`/latest/${model.car_brand_slug}/${model.car_brand_slug}-${model.car_model_slug}`}
              image={model.car_model_image}
              name={model.car_model_name}
              brand={model.car_brand_name}
            />
          </div>
        ))}
      </amp-base-carousel>
      <amp-inline-gallery-pagination
        layout="nodisplay"
        inset
        style={{
          bottom: '-20px',
        }}
      ></amp-inline-gallery-pagination>
    </amp-inline-gallery>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default AmpModelCarousel;
