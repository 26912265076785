import ModelCarousel from '@apps/components/ModelCarousel';
import { IPopularModel } from '@apps/types';
import createBasePath from '@stack/helpers/lib/createBasePath';
import { TitleDivider } from '@stack/ui/components';
import useTranslation from 'next-translate/useTranslation';

export type ModelSectionProps = {
  models: IPopularModel[];
  webView?: boolean;
  title?: string;
  isNewCar?: boolean;
};
/**
 * ===========================
 * MAIN
 * ===========================
 */
export const ModelSection: React.FC<ModelSectionProps> = (props) => {
  const { models, webView = false, title, isNewCar = false } = props;
  // ======================= HOOKS
  const { t } = useTranslation('common');
  const { basePath } = createBasePath({
    webView,
    type: isNewCar ? 'cars' : 'news',
  });

  // ======================= VARIABLES
  const modelList = models?.map((model) => {
    return {
      href: isNewCar
        ? `${basePath}/${model.car_brand_slug}/${model.new_car_slug}`
        : `${basePath}/latest/${model.car_brand_slug}/${model.car_model_slug}`,
      image: model.car_model_image,
      name: model.car_model_name,
      brand: model.car_brand_name,
      new_car_title: model?.new_car_title,
      car_brand_slug: model?.car_brand_slug,
      new_car_slug: model?.new_car_slug,
    };
  });
  // ======================= VIEWS
  if (!models || models.length === 0) return null;
  return (
    <TitleDivider title={title || t('newsByModel')}>
      <ModelCarousel models={modelList} webView={webView} />
      {/* <ModelGroup models={modelList} /> */}
    </TitleDivider>
  );
};
/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default ModelSection;
