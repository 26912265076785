import { Swiper } from '@stack/ui/components';
import styled from 'styled-components';

import CarCard from '../CarCard';
import { CarCarouselProps } from '../CarCarousel';

const CarouselWrapper = styled.div`
  margin-right: 0;

  @media only screen and (max-width: 768px) {
    margin-right: -20px;
  }
`;

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const StandardCarCarousel: React.FC<CarCarouselProps> = (props) => {
  const { cars, webView } = props;

  // ======================= HOOKS

  // ======================= STATE

  // ======================= API

  // ======================= EVENTS

  // ======================= VARIABLES

  // ======================= VIEWS
  return (
    <CarouselWrapper>
      <Swiper
        list={cars}
        navigation={false}
        isSliderButtonCentered
        allowTouchMove
        renderSlide={(car, index) => {
          return (
            <CarCard
              key={index}
              slug={car?.new_car_slug}
              name={car?.new_car_title}
              image={car?.car_model_image}
              monthlyPayment={car?.new_car_monthly_instalment_9}
              priceRange={[car?.lowest_price, car?.highest_price]}
              brand={car?.car_brand_slug}
              isNewCar={true}
              isContentAlign={true}
              webView={webView}
              containerProps={{
                sx: {
                  gap: 2,
                },
              }}
            />
          );
        }}
        spaceBetween={12}
        breakpoints={{
          320: {
            slidesPerView: 1.5,
          },
          375: {
            slidesPerView: 2,
          },
          460: {
            slidesPerView: 2.5,
          },
          640: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 4,
          },
          800: {
            slidesPerView: 4,
          },
        }}
        slidesPerView={{
          extraSmallMobile: 1.5,
          mobile: 3,
          ipad: 5,
          desktop: 6,
          extraBigScreen: 6,
        }}
        height={'200px'}
      />
    </CarouselWrapper>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default StandardCarCarousel;
