/* eslint-disable @typescript-eslint/no-explicit-any */
import { Key } from 'react';
import FuelPriceBox from '@apps/components/FuelPriceBox';
import ImageContent from '@apps/components/ImageContent';
import RunningNumbersBox from '@apps/components/RunningNumbersBox';
import { getDateSlug } from '@apps/utils/get-date-slug';
import placeholderImage from '@stack/assets/images/empty_placeholder.jpg';
import classNames from 'classnames';
import { toLower } from 'lodash';

import {
  ContentWrapper,
  InformativeHolder,
  InnerContentWrapper,
  MostReadContentWrapper,
  MostReadWrapper,
  NewsHolder,
  SectionWrapper,
  StickyHolder,
} from './styles';
/**
 * ===========================
 * MAIN
 * ===========================
 */
export const FeatureNews: React.FC<any> = (props) => {
  const { fuels, latestNews, jpjNumber, sideNews, webView } = props;
  // ======================= HOOKS

  // ======================= STATE
  const remainRead = latestNews ?? [];
  // ======================= API

  // ======================= EVENTS

  // ======================= VARIABLES

  // ======================= RENDER FUNCTIONS
  const getPostHref = (post: any) => {
    if (!post?.post_title) return;
    const dateSlug = getDateSlug(post.post_created);
    return webView
      ? `/webview/${dateSlug}/${toLower(post?.post_slug)}`
      : `/${dateSlug}/${toLower(post?.post_slug)}`;
  };

  // ======================= VIEWS
  return (
    <SectionWrapper>
      <NewsHolder>
        <ContentWrapper className="list">
          {remainRead.map((rd: any, index: Key) => {
            return (
              <InnerContentWrapper
                className="list-item list-border"
                key={index}
              >
                <ImageContent
                  title={rd.post_title}
                  titleSlug={rd?.post_slug}
                  description={rd.post_short_description}
                  badgeLabel={rd.category_name}
                  footerText={rd?.post_created}
                  imageProps={{
                    src: rd?.post_thumbnail_id || placeholderImage.src,
                    width: 600,
                    height: 315,
                    alt: rd?.post_title,
                  }}
                  imagePosition="left"
                  tabletImagePosition="left"
                  author={rd?.post_author}
                  authorAvatar={rd?.post_author_profile_image}
                  authorEmail={rd?.post_author_username}
                  maxImageWidth="30%"
                  footerTextJustify="flex-start"
                  href={getPostHref(rd)}
                  titleLine={2}
                  hideDescription={true}
                  isList={true}
                  webView={webView}
                />
              </InnerContentWrapper>
            );
          })}
        </ContentWrapper>
        {/* <FooterActionWrapper>
          <ShowMoreButton href="/" />
        </FooterActionWrapper> */}
      </NewsHolder>
      <StickyHolder>
        <ContentWrapper className="list">
          {!webView && (
            <InformativeHolder className="list-item">
              <RunningNumbersBox
                lastUpdatedAt={jpjNumber?.last_updated}
                runningNumbers={jpjNumber?.data}
                webView={webView}
              />
              <FuelPriceBox
                lastUpdatedAt="25-02-2024 (WEDNESDAY)  6:00PM"
                fuels={fuels}
              />
            </InformativeHolder>
          )}
          <MostReadWrapper className="list-item desktop-list-border-only">
            <MostReadContentWrapper className="list">
              {sideNews?.data?.map((rd: any, index: Key) => {
                return (
                  <InnerContentWrapper
                    className={classNames('list-item mobile-list-border-only', {
                      'list-border': webView,
                    })}
                    key={index}
                  >
                    <ImageContent
                      title={rd.post_title}
                      titleSlug={rd?.post_slug}
                      description={rd?.post_short_description}
                      badgeLabel={rd?.category_name}
                      footerText={rd?.post_created}
                      author={rd?.post_author}
                      imagePosition="top"
                      tabletImagePosition="top"
                      authorAvatar={rd?.post_author_profile_image}
                      authorEmail={rd?.post_author_username}
                      imageProps={{
                        src: rd?.post_thumbnail_id || placeholderImage.src,
                        width: 600,
                        height: 315,
                        alt: rd?.post_title,
                      }}
                      href={getPostHref(rd)}
                      titleLine={2}
                      hideDescription={true}
                      isList={true}
                      webView={webView}
                    />
                  </InnerContentWrapper>
                );
              })}
            </MostReadContentWrapper>
          </MostReadWrapper>
        </ContentWrapper>
      </StickyHolder>
    </SectionWrapper>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default FeatureNews;
