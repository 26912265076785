/* eslint-disable @typescript-eslint/no-explicit-any */
import CarCarousel from '@apps/components/CarCarousel';
import { TitleDivider } from '@stack/ui/components';
import dynamic from 'next/dynamic';

import { DesktopWrapper, MobileWrapper } from './styles';

const AdsContainer = dynamic<any>(() => import('../AdsContainer'), {
  ssr: false,
});

export type NewEvCarSectionProps = {
  cars: any;
  webView?: boolean;
};

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const NewEvCarSection: React.FC<NewEvCarSectionProps> = (props) => {
  const { cars, webView = false } = props;
  if (!cars) return;
  // ======================= HOOKS

  // ======================= STATE

  // ======================= API

  // ======================= EVENTS

  // ======================= VARIABLES

  // ======================= RENDER FUNCTIONS

  // ======================= VIEWS
  if (webView) return null;
  return (
    <TitleDivider title="New EV Car">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CarCarousel cars={cars?.new_car ?? []} webView={webView} />
        {/* Ads Section */}
        {!webView && (
          <MobileWrapper
            className="adsbygoogle"
            style={{
              backgroundColor: '#F7F7F7',
            }}
          >
            {/* <AdSense
              adSlot={'5942712071'}
              style={{
                display: 'block',
              }}
            /> */}
            <AdsContainer
              adSlot={'5942712071'}
              style={{
                display: 'block',
              }}
              localPlacement={'sm_listing'}
            />
          </MobileWrapper>
        )}
        {!webView && (
          <DesktopWrapper
            className="adsbygoogle"
            style={{
              backgroundColor: '#F7F7F7',
              justifyContent: 'center',
            }}
          >
            {/* <AdSense
              adSlot={'1910211105'}
              style={{
                display: 'block',
              }}
            /> */}
            <AdsContainer
              adSlot={'1910211105'}
              style={{
                display: 'block',
              }}
              localPlacement={'xl_listing'}
            />
          </DesktopWrapper>
        )}
      </div>
    </TitleDivider>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default NewEvCarSection;
