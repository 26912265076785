import { useAmp } from 'next/amp';

import { ModelItemProps } from '../ModelGroup/ModelItem';

import AmpModelCarousel from './AmpModelCarousel';
import StandardModelCarousel from './StandardModelCarousel';

export type ModelCarouselProps = {
  models: ModelItemProps[];
  webView?: boolean;
};

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const ModelCarousel: React.FC<ModelCarouselProps> = (props) => {
  const { models, webView = false } = props;

  // ======================= HOOKS
  const isAmp = useAmp();

  // ======================= VIEWS
  if (!isAmp) {
    return <StandardModelCarousel models={models} webView={webView} />;
  }
  return <AmpModelCarousel models={models} />;
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default ModelCarousel;
