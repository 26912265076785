/* eslint-disable @typescript-eslint/no-explicit-any */
import { Key } from 'react';
import ImageContent from '@apps/components/ImageContent';
import { getDateSlug } from '@apps/utils/get-date-slug';
import placeholderImage from '@stack/assets/images/empty_placeholder.jpg';
import { TitleDivider } from '@stack/ui/components';
import dynamic from 'next/dynamic';
import useTranslation from 'next-translate/useTranslation';
const AdsContainer = dynamic<any>(() => import('../AdsContainer'), {
  ssr: false,
});

import { toLower } from 'lodash';

import {
  BlockInner,
  ContentWrapper,
  InnerContentWrapper,
  SectionWrapper,
} from './styles';
/**
 * ===========================
 * MAIN
 * ===========================
 */
export const MostReadSection: React.FC<any> = (props) => {
  const { news, webView } = props;
  // ======================= HOOKS
  const { t } = useTranslation('common');

  // ======================= STATE
  const TopRead = news?.[0];
  const remainRead = news.filter((rd: any, index: Key) => {
    return index !== 0;
  });

  // ======================= API

  // ======================= EVENTS

  // ======================= VARIABLES

  // ======================= RENDER FUNCTIONS
  const getPostHref = (post: any) => {
    if (!post?.post_title) return;
    const dateSlug = getDateSlug(post.post_created);
    return webView
      ? `/webview/${dateSlug}/${toLower(post?.post_slug)}`
      : `/${dateSlug}/${toLower(post?.post_slug)}`;
  };

  // ======================= VIEWS
  return (
    <TitleDivider title={t('mostRead')}>
      <SectionWrapper>
        {/* Ads Section */}
        <div
          className="adsbygoogle"
          style={{
            display: 'block',
            backgroundColor: '#F7F7F7',
          }}
        >
          {!webView && (
            // <AdSense
            //   adSlot={'5942712071'}
            //   style={{
            //     display: 'block',
            //   }}
            // />
            <AdsContainer
              adSlot={'5942712071'}
              style={{
                display: 'block',
              }}
              localPlacement={'home'}
            />
          )}
        </div>
        <div>
          <BlockInner>
            <ImageContent
              imageProps={{
                src: TopRead?.post_thumbnail_id || placeholderImage.src,
                width: 600,
                height: 315,
                alt: TopRead?.post_title,
              }}
              imagePosition="right"
              titleClass="text-4xl"
              title={TopRead?.post_title}
              description={TopRead?.post_short_description}
              badgeLabel={TopRead?.category_name}
              footerText={TopRead?.post_created}
              author={TopRead?.post_author}
              authorAvatar={TopRead?.post_author_profile_image}
              authorEmail={TopRead?.post_author_username}
              footerTextJustify="flex-start"
              href={getPostHref(TopRead)}
              webView={webView}
            />
          </BlockInner>
          <ContentWrapper>
            {remainRead?.map((rd: any, index: Key) => {
              if ((index as number) > 2) return;
              return (
                <InnerContentWrapper
                  className="grid-item grid-border"
                  key={index}
                >
                  <ImageContent
                    title={rd.post_title}
                    titleSlug={rd?.post_slug}
                    titleLine={2}
                    footerText={rd?.post_created}
                    author={rd?.post_author}
                    badgeLabel={rd?.category_name}
                    authorAvatar={rd?.post_author_profile_image}
                    authorEmail={rd?.post_author_username}
                    href={getPostHref(rd)}
                    webView={webView}
                    imageProps={{
                      src: rd?.post_thumbnail_id || placeholderImage.src,
                      width: 600,
                      height: 315,
                      alt: rd?.post_title,
                    }}
                  />
                </InnerContentWrapper>
              );
            })}
          </ContentWrapper>
        </div>
      </SectionWrapper>
      {/* <ShowMoreButton href="/" /> */}
    </TitleDivider>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default MostReadSection;
