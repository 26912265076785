import ShowMoreButton from '@apps/components/ShowMoreButton';
import { TitleDivider } from '@stack/ui/components';
import useTranslation from 'next-translate/useTranslation';
import styled from 'styled-components';

import {
  SocialVideoChannels,
  SocialVideoChannelsProps,
} from '../../components/SocialVideoChannels/index';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media only screen and (max-width: 768px) {
    gap: 20px;
  }
`;

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const SocialVideoSection: React.FC<SocialVideoChannelsProps> = (
  props
) => {
  const { data, setChannelType, loading } = props;

  // ======================= HOOKS
  const { t } = useTranslation('common');

  // ======================= RENDER FUNCTIONS

  // ======================= VIEWS
  return (
    <TitleDivider title={t('socialVideo')}>
      <SectionWrapper>
        {/* {renderSocialIcons()} */}
        <SocialVideoChannels
          data={data}
          setChannelType={setChannelType}
          loading={loading}
        />
        {/* <SocialVideoCardGrid>
          {videoData.map((video, index) => {
            return (
              <SocialVideoCard
                key={index}
                title={video.title}
                thumbnail={video.thumbnail}
                href={video.href}
                views={video.views}
              />
            );
          })}
        </SocialVideoCardGrid>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
            gap: 20,
          }}
        >
          {youtubeVideoData.map((video, index) => {
            return (
              <VideoCard
                key={index}
                title={video.title}
                thumbnail={video.thumbnail}
                avatar={video.avatar}
                channel={video.channel}
                date={video.date}
                link={video.link}
              />
            );
          })}
        </div> */}
        <ShowMoreButton href="/social-video" />
      </SectionWrapper>
    </TitleDivider>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default SocialVideoSection;
