import axios from 'axios';
import md5 from 'md5';

type Props = {
  id: string;
  type: string;
  platform?: string;
};

export const graphAnalytics = async (props: Props) => {
  const { id, platform, type } = props;

  try {
    if (
      process.env.NEXT_PUBLIC_API_URL &&
      process.env.NEXT_PUBLIC_ENCRYPTION_CODE
    ) {
      const encryptionCode = process.env.NEXT_PUBLIC_ENCRYPTION_CODE;

      const encrypt_code = md5(id + encryptionCode);

      const url = `${
        process.env.NEXT_PUBLIC_API_URL
      }/graph_analytics/${type}?id=${id}&platform=${
        platform || 'carz'
      }&encrypt=${encrypt_code}`;

      await axios.get(url);
    }
  } catch (e) {
    console.log(`[Error] Ads Graph Analytic (${type}): `, e);
  }
};
