/* eslint-disable @typescript-eslint/no-explicit-any */

import { Key } from 'react';
import { getDateSlug } from '@apps/utils/get-date-slug';
import placeholderImage from '@stack/assets/images/empty_placeholder.jpg';
import { BorderTitle } from '@stack/ui/components';
import { toLower } from 'lodash';

import ImageContent from '../ImageContent';
import ShowMoreButton from '../ShowMoreButton';

import { NewsCategoryProps } from './props';
import { ContentWrapper, InnerContentWrapper } from './styles';

export const NewsCategory: React.FC<NewsCategoryProps> = (props) => {
  // ======================= PROPS
  const { title, titleUrl, data, enableImagePosition, webView } = props;

  // ======================= STATE
  const remainRead = data?.slice(0, 5);

  const getPostHref = (post: any) => {
    if (!post?.post_title) return;
    const dateSlug = getDateSlug(post.post_created);
    return webView
      ? `/webview/${dateSlug}/${toLower(post?.post_slug)}`
      : `/${dateSlug}/${toLower(post?.post_slug)}`;
  };

  // ======================= VIEWS
  return (
    <BorderTitle title={title} url={titleUrl}>
      <ContentWrapper className="list" style={{ marginTop: '20px' }}>
        {remainRead?.map((rd: any, index: Key) => {
          const hasImage = enableImagePosition === index;
          return (
            <InnerContentWrapper className="list-item list-border" key={index}>
              <ImageContent
                title={rd?.post_title}
                titleSlug={rd?.post_slug}
                titleClass={hasImage ? undefined : 'custom-text-base'}
                description={hasImage ? rd?.post_short_description : undefined}
                badgeLabel={rd?.category_name}
                footerText={rd?.post_created}
                author={rd?.post_author}
                authorAvatar={rd?.post_author_profile_image}
                authorEmail={rd?.post_author_username}
                imageProps={{
                  src: rd?.post_thumbnail_id || placeholderImage.src,
                  width: 600,
                  height: 315,
                  alt: rd?.post_title,
                }}
                maxImageWidth={hasImage ? '100%' : '40%'}
                simpleMobileView={true}
                maxTabletImageWidth={'45%'}
                imagePosition={hasImage ? 'top' : 'left'}
                tabletImagePosition={hasImage ? 'top' : 'left'}
                mobileImagePostion="left"
                descriptionLine={2}
                titleLine={2}
                href={getPostHref(rd)}
                webView={webView}
              />
            </InnerContentWrapper>
          );
        })}
        <ShowMoreButton href={titleUrl as string} />
      </ContentWrapper>
    </BorderTitle>
  );
};

// ======================= EXPORT
export * from './props';
export default NewsCategory;
