import React, { memo, useCallback } from 'react';
import StyledImage from '@apps/components/Image';
import usePostMsgContext from '@apps/containers/WebviewProvider/hooks';
import { graphAnalytics } from '@apps/utils/graph_analytics';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Swiper } from '@stack/ui/components';
import Link from 'next/link';

import { HomeBannerProps, SettingBannerType } from './props';

export const HomeBanner: React.FC<HomeBannerProps> = (props) => {
  // ======================= PROPS
  const { data, webView } = props;

  // ======================= HOOKS
  const isMobile = useMediaQuery('(max-width:768px)');
  const { clickEvent } = usePostMsgContext();

  // ======================= EVENTS

  const onBannerClick = useCallback((item: SettingBannerType) => {
    graphAnalytics({
      id: `${item?.banner_id}`,
      type: 'setting_banner_total_view',
    });
  }, []);

  const onBannerSlideChange = useCallback(
    (index: number | null) => {
      if (index !== null) {
        graphAnalytics({
          id: `${data[index]?.banner_id}`,
          type: 'setting_banner_total_impression',
        });
      }
    },
    [data]
  );

  // ======================= VIEWS
  return (
    <Swiper
      list={data}
      loop
      autoPlay
      speed={400}
      isSliderButtonCentered
      navigationProps={{ sx: { visibility: 'visible' } }}
      onSlideChangeTransitionEnd={(item) => {
        onBannerSlideChange(item.realIndex || null);
      }}
      swiperStyle={{
        width: '100%',
        height: 'unset',
        aspectRatio: isMobile ? 3 / 2 : 3 / 1,
      }}
      renderSlide={(item) => {
        return (
          <Link
            target={webView ? undefined : '_blank'}
            href={item?.banner_link}
            style={{
              pointerEvents: item?.banner_link ? 'auto' : 'none',
              flex: 1,
            }}
            onClick={(event) => {
              clickEvent?.(item?.banner_link, false, {
                title: 'Banner',
                params: {
                  popup: true,
                },
              })?.(event);
              onBannerClick(item);
            }}
          >
            <StyledImage
              src={
                isMobile
                  ? item?.banner_image_mobile || ''
                  : item?.banner_image || ''
              }
              alt=""
              imageStyle={{
                objectFit: 'contain',
                height: '100%',
              }}
            />
          </Link>
        );
      }}
    />
  );
};

// ======================= EXPORT
export default memo(HomeBanner);
