/* eslint-disable @typescript-eslint/no-explicit-any */
import NewsCategory from '@apps/components/NewsCategory';
import { localeConversions } from '@apps/utils/locale-conversion';
import useTranslation from 'next-translate/useTranslation';

import { Box } from './styles';

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const NewsCategorySection: React.FC<any> = (props) => {
  // ======================= HOOKS
  const { reviewNews, techNews, tipsNews, webView } = props;
  const { lang } = useTranslation('common');
  const newLang = localeConversions(lang);

  // ======================= STATE

  // ======================= API

  // ======================= EVENTS

  // ======================= VARIABLES

  // ======================= RENDER FUNCTIONS

  // ======================= VIEWS
  return (
    <Box className="grid grid-3-col">
      <div className="grid-item custom-grid-border">
        <NewsCategory
          title={reviewNews?.post_category_name[newLang]}
          data={reviewNews?.data}
          enableImagePosition={0}
          titleUrl={
            webView
              ? `/webview/${reviewNews?.post_category_slug}`
              : `/${reviewNews?.post_category_slug}`
          }
          webView={webView}
        />
      </div>
      <div className="grid-item custom-grid-border">
        <NewsCategory
          title={techNews?.post_category_name[newLang]}
          data={techNews?.data}
          enableImagePosition={4}
          titleUrl={
            webView
              ? `/webview/${techNews?.post_category_slug}`
              : `/${techNews?.post_category_slug}`
          }
          webView={webView}
        />
      </div>
      <div className="grid-item custom-grid-border">
        <NewsCategory
          title={tipsNews?.post_category_name[newLang]}
          data={tipsNews?.data}
          enableImagePosition={0}
          titleUrl={
            webView
              ? `/webview/${tipsNews?.post_category_slug}`
              : `/${tipsNews?.post_category_slug}`
          }
          webView={webView}
        />
      </div>
    </Box>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default NewsCategorySection;
