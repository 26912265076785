/* eslint-disable @typescript-eslint/no-explicit-any */
import { CarCardProps } from '@apps/components/CarCard';
import { useAmp } from 'next/amp';

import AmpCarCarousel from './AmpCarCarousel';
import StandardCarCarousel from './StandardCarCarousel';

export type CarCarouselProps = {
  cars: CarCardProps[] | any;
  webView?: boolean;
};

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const CarCarousel: React.FC<CarCarouselProps> = (props) => {
  const { cars, webView } = props;

  // ======================= HOOKS
  const isAmp = useAmp();

  // ======================= STATE

  // ======================= API

  // ======================= EVENTS

  // ======================= VARIABLES

  // ======================= RENDER FUNCTIONS

  // ======================= VIEWS
  if (!isAmp) {
    return <StandardCarCarousel cars={cars} webView={webView} />;
  }
  return <AmpCarCarousel cars={cars} webView={webView} />;
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default CarCarousel;
